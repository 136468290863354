<template>
  <Button  template="icon-right" v-bind="$attrs" >
      <span class="label">
        <slot name="label"> {{ label }} </slot>
      </span>
      <icon :icon="icon"></icon>
  </Button>
</template>

<script>
import Button from '../_shared/button-skeleton.vue';
import Icon from 'vue-elements/icon/Icon.vue';

export default {
  name: 'ButtonIconRight',
  components: {
    Button,
    Icon
  },
  props: {
    label: {
      type: String,
    },
    icon: {
      type: String,
      default: 'arrow-right-long-solid'
    },
  }
};
</script>
