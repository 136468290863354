<template>
  <button :class="buttonClass" role="button" >
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'Button',
  props: {
    template: String,
    type: String,

  },
  computed: {
    buttonClass() {
      return `button-element ${this.template} ${this.type}`
    }
  }
};

</script>
